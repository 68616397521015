import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import * as styles from "./OrganizationCard.module.scss";

const OrganizationItem1 = () => {
  return (
    <div className={styles.item}>
      <div className={styles.wrapper}>
        <p className={styles.rank}>첫번째,</p>
        <p className={styles.title}>
          데이터 중심의
          <br />
          의사결정
        </p>
        <p className={styles.subtitle}>
          가설/실험/데이터/지표/AB테스트 기반 의사 결정
        </p>
      </div>
    </div>
  );
};

const OrganizationItem2 = () => {
  return (
    <div className={styles.item}>
      <div className={styles.wrapper}>
        <p className={styles.rank}>두번째,</p>
        <p className={styles.title}>
          형식을 타파하고
          <br />
          본질과 핵심에 집중
        </p>
        <p className={styles.subtitle}>
          업무 전반의 비효율성 타파. 업무 효율화를 위한 <br />
          업무지원 시스템 도입, 반복 업무 최소화, <br />
          포멀한 회의보다는 상시 소통
        </p>
      </div>
    </div>
  );
};

const OrganizationItem3 = () => {
  return (
    <div className={styles.item}>
      <div className={styles.wrapper}>
        <p className={styles.rank}>세번째,</p>
        <p className={styles.title}>
          계획은 신중하게,
          <br />
          실행은 빠르게
        </p>
        <p className={styles.subtitle}>
          작은 반복으로 빠른 결과물,시장을 통한 학습
        </p>
      </div>
    </div>
  );
};

const OrganizationCultureCard = () => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <section className={styles.container}>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        centeredSlidesBounds={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        onSwiper={setSwiper}
        onBeforeTransitionStart={(swiper) => {
          setCurrentIndex(swiper.realIndex)
        }}
      >
        <SwiperSlide>
          <OrganizationItem1 />
        </SwiperSlide>
        <SwiperSlide>
          <OrganizationItem2 />
        </SwiperSlide>
        <SwiperSlide>
          <OrganizationItem3 />
        </SwiperSlide>
      </Swiper>
      {currentIndex !== 0 && <button 
        className={styles.prev}
        onClick={() => swiper?.slidePrev()}
      >
        Prev
      </button>}
      {currentIndex !== swiper?.slides.length - 1 && <button
        className={styles.next}
        onClick={() => swiper?.slideNext()}
      >
        Next
      </button>}
    </section>
  );
};

export default OrganizationCultureCard;
