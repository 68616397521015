import React from "react";
import * as styles from "./Category.module.scss";
export interface CategoryType {
  title: string;
  type: number;
  isActive: boolean;
  onCategoryClick?: (type: number) => void;
}

const Category = ({ title, isActive, type, onCategoryClick }: CategoryType) => {
  const handleClick = () => {
    onCategoryClick && onCategoryClick(type);
  };

  return (
    <a
      onClick={handleClick}
      className={`${styles.item} ${
        isActive ? styles.wrapper_active : styles.wrapper_unactive
      }`}
    >
      <p className={styles.title}>{title}</p>
    </a>
  );
};

export default Category;
