import React from "react";
import * as styles from "./MainValueCard.module.scss";

const MainValueCard = () => {
  return (
    <section className={styles.container}>
      <div className={styles.item}>
        <p>
          고객에 대해서 집요하게 탐구,
          <br />
          고객의 관점에서 문제를 해결합니다.
        </p>
      </div>
      <div className={styles.item}>
        <p>
          고객에게 어떤 가치를 줄 수 있는가가
          <br />
          의사결정의 최우선되는 기준입니다.
        </p>
      </div>
    </section>
  );
};

export default MainValueCard;
