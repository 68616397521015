import React from "react";

import PageView from "@components/View/PageView";
import CardView from "@components/View/CardView";
import Wrapper from "@components/Wrapper";

import MainValueCard from "./components/MainValueCard";
import HowToWorkCard from "./components/HowToWorkCard";
import WelfareCard from "./components/WelfareCard";
import OrganizationCard from "./components/OrganizationCard";

const CultureModule = () => (
  <Wrapper>
    <PageView title="올리브영 Tech, 어떻게 일하나요?">
      <CardView title="고객의 신뢰와 경험을 최우선">
        <MainValueCard />
      </CardView>
      <CardView title="조직문화">
        <OrganizationCard />
      </CardView>
      <CardView title="이렇게 일해요">
        <HowToWorkCard />
      </CardView>
      <CardView title="일하는 환경">
        <WelfareCard />
      </CardView>
    </PageView>
  </Wrapper>
);

export default CultureModule;
