import React from "react";
import Seo from "@components/Seo";
import Layout from "@components/Layout";

import CultureModule from "@modules/Culture";

const CulturePage = () => (
  <Layout>
    <Seo
      title="문화"
      description="고객의 신뢰와 경험을 최우선하는 올리브영 Tech 조직문화, 일하는 방식, 일하는 환경을 소개합니다."
      url="/culture"
    />
    <CultureModule />
  </Layout>
);

export default CulturePage;
