import React, { useState } from "react";
import * as styles from "./WelfareCard.module.scss";
import Category, { CategoryType } from "@components/Category";

enum Welfare {
  ALL,
  WORK,
  LIFE,
  VACATION,
}

interface WelfareCardType {
  icon?: string;
  title?: string;
  subtitle?: string;
  description?: string[];
}

const welfare = [
  {
    type: Welfare.WORK,
    title: "유연한 장비선택",
    subtitle: "최적의 업무 환경을 위한 지원을 아끼지 않습니다.",
    icon: "ic_computer.png",
    description: [
      "맥북M1과 맥북프로부터 LG 그램 및 데스크톱까지 본인의 선호에 맞게 개발 장비를 선택할 수 있습니다.",
      "구성원들의 선호에 따라 모니터암이나 나뭇잎 캐노피도 설치해드립니다.",
    ],
  },
  {
    type: Welfare.WORK,
    title: "충분한 교육지원",
    subtitle: "하고싶은 공부를 마음껏 할 수 있습니다.",
    icon: "ic_study.png",
    description: [
      "세미나/컨퍼런스 등의 외부행사 참가를 적극적으로 권장합니다.",
      "매주 자율적인 사내스터디를 시행하고 있습니다.",
      "기술 관련 서적 및 읽고 싶은 책이 있다면 팀장님이 사비를 털어서라도 꼭 사줍니다. (물론 그럴 필요 없이 회사에서 지원해줍니다.)",
    ],
  },
  {
    type: Welfare.WORK,
    title: "자유로운 출퇴근 조정",
    subtitle: "출퇴근 시간 및 하루 근무시간을 자유롭게 조정합니다.",
    icon: "ic_clock.png",
    description: [
      "근무시간을 자유롭게 조정하는 선택근무와 탄력근무 제도를 시행하고 있습니다. 주 52시간 내에서 본인의 상황에 맞게 유연하고 탄력적으로 근무시간을 조정할 수 있습니다.",
      "자발적 업무몰입과 정시퇴근 문화 정착을 위한 PC-Off제를 시행하고 있습니다. 약속된 시간에 집중하여 성과를 창출하는 문화를 조성합니다.",
    ],
  },
  {
    type: Welfare.WORK,
    title: "진짜 수평문화",
    subtitle: "수평적이고 상호 존중하는 조직문화를 선도합니다.",
    icon: "ic_conversation.png",
    description: [
      "상호 간의 동등한 '님' 호칭을 사용합니다.",
      "말로만 하는 '수평'이 아닌, 마치 샌프란시스코에 온 것 같은 착각마저 드는 '찐' 수평 문화를 보실 수 있습니다.",
    ],
  },
  {
    type: Welfare.WORK,
    title: "적극적인 코드리뷰",
    subtitle: "부드러운 대화와 적극적인 토론을 합니다.",
    icon: "ic_code.png",
    description: [
      "git pull-request를 통한 온라인 코드리뷰 문화가 정착되어 있습니다.",
      "자유로운 분위기의 토론 및 상호 간의 둥글고 부드러운 코드리뷰가 매일 활발하게 진행됩니다.",
    ],
  },
  {
    type: Welfare.WORK,
    title: "따듯한 환영",
    subtitle: "신규 구성원이 빠르게 적응할 수 있도록 최선을 다합니다.",
    icon: "ic_smile.png",
    description: [
      "후드티 및 각종 필기류를 포함한 웰컴키트를 제공합니다.",
      "다과와 함께 편안한 분위기 속에서 소소한 이야기까지 전부 들려드립니다. 복스뮤직의 1인 아카펠라부터 여니님의 풍물놀이까지 보실 수 있습니다.",
      "충분한 시간을 가지고 S-OJT 등의 교육을 진행하며, 입사 시 매칭되는 버디가 항상 든든한 도우미가 되어줍니다.",
    ],
  },
  {
    type: Welfare.VACATION,
    title: "각종 휴가 지원",
    subtitle: "휴가에는 사유가 없습니다.",
    icon: "ic_vacation.png",
    description: [
      "자유로운 각종 연차휴가 사용은 물론 국내/해외 호텔 및 렌터카 등을 지원합니다.",
      "개인의 창의성 제고와 트랜드 캐칭을 위해 근속 연수에 따라 최대 4주간의 재충전 휴가를 지원합니다.",
      "글로벌 역량 강화를 위해 만 5년 이상 근속한 구성원들은 최소 3개월부터 최대 1년까지 자기 주도적인 글로벌 연수를 다녀올 수 있습니다.",
    ],
  },
  {
    type: Welfare.VACATION,
    title: "패밀리 지향",
    subtitle: "임직원 뿐 아니라 임직원의 가족까지 구성원으로 생각합니다.",
    icon: "ic_home.png",
    description: [
      "각종 휴직 및 근로시간 단축 복리후생을 적극적으로 권장하고 있습니다.",
      "자녀 학자금 지원 및 사회보험/의료지원제도를 적극적으로 시행하고 있습니다.",
      "건강유지에 대한 불안감과 발병 시 의료비에 대한 부담감을 해소하여 근무에 전념할 수 있게 하도록 의료비를 지원합니다.",
    ],
  },
  {
    type: Welfare.LIFE,
    title: "CJ Members Card 제공",
    subtitle: "CJ 전 계열사 임직원 할인이 적용됩니다.",
    icon: "ic_card.png",
    description: [
      "CJ 전 계열사에서 40% 내외의 제휴할인이 가능합니다.",
      "올리브영부터 CGV, 투썸플레이스, 뚜레쥬르 등 수 많은 계열사에서 임직원 할인이 가능합니다.",
    ],
  },
  {
    type: Welfare.LIFE,
    title: "경조사 지원",
    subtitle: "기쁨과 슬픔을 함께 나눕니다.",
    icon: "ic_gift.png",
    description: [
      "각종 경조사에서 경조금과 휴가를 지원합니다.",
      "웨딩카와 예식장 및 상조 담당 인력 등 CJ만의 특색있는 경조사 지원제도를 이용할 수 있습니다.",
    ],
  },
  {
    type: Welfare.LIFE,
    title: "카페테리아 포인트",
    subtitle: "자유롭게 사용가능한 연간 카페테리아 포인트를 지급합니다.",
    icon: "ic_money.png",
    description: [
      "부여받은 포인트 내에서 자유롭게 필요항목 및 수혜수준을 스스로 선택하여 사용할 수 있습니다.",
      "여가/문화생활을 위한 여행비 지원부터 학자금/자기계발 등 다양한 복리후생 서비스를 이용할 수 있습니다.",
    ],
  },
];

const WelfareItem = ({
  icon,
  title,
  subtitle,
  description,
}: WelfareCardType) => {
  return (
    <article className={styles.item}>
      <img
        src={`/assets/icon/${icon}`}
        className={styles.icon}
        alt={`올리브영 테크 블로그 올리브영 Tech 일하는 환경 ${title}`}
      />
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
      <div>
        {description?.map((list) => (
          <p className={styles.description} key={`card1_복지제도_${list}`}>
            {list}
          </p>
        ))}
      </div>
    </article>
  );
};

const WelfareCard = () => {
  const [category, setCategory] = useState<CategoryType[]>([
    { title: "전체", type: Welfare.ALL, isActive: true },
    { title: "업무 집중 지원", type: Welfare.WORK, isActive: false },
    { title: "생활 밀착 지원", type: Welfare.LIFE, isActive: false },
    { title: "휴식 지원", type: Welfare.VACATION, isActive: false },
  ]);

  const [welfareData, setWelfareData] = useState(welfare);

  const handleCategoryClick = (type: number) => {
    setCategory(
      category.map((cate) =>
        cate.type === type
          ? { ...cate, isActive: true }
          : { ...cate, isActive: false }
      )
    );

    setWelfareData(
      type === Welfare.ALL
        ? welfare
        : welfare.filter((data) => data.type === type)
    );
  };

  return (
    <section>
      {category.map((item) => (
        <Category
          key={`welfare_cate_${item.title}`}
          title={item.title}
          type={item.type}
          isActive={item.isActive}
          onCategoryClick={handleCategoryClick}
        />
      ))}
      <div className={styles.container}>
        {welfareData?.map((item) => (
          <WelfareItem
            key={`welfare_${item.title}`}
            title={item.title}
            subtitle={item.subtitle}
            icon={item.icon}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default WelfareCard;
