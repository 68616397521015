import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./HowToWorkCard.module.scss";

const groundRule = [
  "업무를 요청할 때는 먼저 해당 업무의 목적과 배경에 대해서 설명해주세요.",
  "사전에 공유된 명확한 목표와 아젠다가 있는 회의만 진행하며, 회의 시작시간과 끝시간을 준수합니다.",
  "화상회의 시에는 상호 이해와 공감을 높이기 위해서 카메라를 켜주세요.",
  "의사결정은 수직적이어도 논의는 수평적으로 합니다.",
  "디지털사업본부의 업무 공용어는 존대어입니다.",
  "실패와 실수는 다릅니다.똑똑한 실패는 권장하지만, 반복되는 실수는 지양합니다.",
  "혼자서 할 수 있는 일은 없습니다. 나의 업무를 동료들과 공유해주세요.",
];

const HowToWorkCard = () => {
  return (
    <section>
      <StaticImage
        src="../../../../static/assets/background/bg_ground_rule.png"
        alt="올리브영 테크 블로그 올리브영 Tech 조직문화 일하는 방식"
        layout="fullWidth"
      />
      <div className={styles.container}>
        {groundRule.map((rule, index) => (
          <article className={styles.item} key={`ground_rule_${rule}`}>
            <p className={styles.rank}>{index + 1}</p>
            <p className={styles.title}>{rule}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default HowToWorkCard;
